<template>
  <div class="full_popup">
    <div class="row h-100 m-0 align-items-center justify-content-center">
      <div class="col-11 col-md-8 col-lg-7 col-xl-6 col-xxl-5 card_item p-0">
        <div class="row mx-0 h-100 justify-content-center">
          <div class="col-12 pt-3 px-3 white_bg">
            <div class="row m-0 justify-content-center holder">
              <div class=" bg_holder">
                <div class="background" >
                </div>
              </div>
              <div class="col-12 above">
                <div class="row m-0 justify-content-center">
                  <div class="col-12 p-0">
                    <div class="row m-0 justify-content-end">
                      <div class="col-auto text-end pt-1 pe-1 cursor" @click="$emit('close')">
                        <ios-close-icon class="inline-icon big" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 above">
                <div class="row mx-0 justify-content-center">
                  <div class="col-12 p-0">
                    <div class="row m-0 justify-content-center">
                      <div class="col-auto text-center px-0 pb-3 border_bottom mb-3">
                        <div class="card_title">
                          {{ title }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <slot name="header"></slot>
                </div>
              </div>
            </div>
          </div>
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  props: ['title'],
  name: 'Recurring donations Popup'
}
</script>

<style scoped>
.full_popup {
  position: fixed;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
}

.card_item {
  /* background-color: #fff; */
  border-radius: 22px;
  box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 4.3px;
}

.border_bottom {
  border-bottom: 1px solid var(--gold-color);
}
.holder {
  position: relative;
  z-index: 2;
}
.above {
  z-index: inherit;
}
.bg_holder {
  position: absolute;
  left: -1.5rem;
  top: -1.5rem;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  background-image: linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  max-width: calc(100% + 3rem);
  width: calc(100% + 3rem);
  height: calc(100% + 1.5rem);
  padding: 0;
}
.background {
  background-image: url(../../../assets/images/bg.png);
  position: absolute;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  background-size: 30% auto;
  opacity: 0.03;
  background-position: top center;
  height: 100%;
  width: 100%;
}

</style>
